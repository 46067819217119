import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
import Instabug from '../../components/Instabug';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <blockquote>
      <p parentName="blockquote">{`Update (2/22/2020): The Instabug ads ran for three months on noahgilmore.com and generated 14 dollars in revenue. Given this, I've removed them from the site.`}</p>
    </blockquote>
    <p>{`When browsing noahgilmore.com/blog starting today, you may see sponsored content - sometimes in the sidebar and sometimes in sections like this:`}</p>
    <Instabug mdxType="Instabug" />
    <p>{`I'll be piloting these sponsored sections on some posts (generally the longer form, higher effort ones) in the coming months. Writing high quality content on this blog takes a fair amount of work - drafting, editing, testing, creating example code, recording gifs, etc. The revenue I get through sponsorships helps make the investment worth it from a financial perspective, and hopefully I'm able to pay it forward by continuing to create content for the community.`}</p>
    <p>{`In the interest of transparency, I wanted to take the time to highlight a few points:`}</p>
    <ol>
      <li parentName="ol">{`I'm paid directly per-click or per-impression for these advertisements, no third parties involved. I manage contracts directly, which means I'm vouching for the quality and relevance of the ads. In the future I may look into using indie-focused ad networks, but I'll never compromise reader experience by using Google AdSense, for example.`}</li>
      <li parentName="ol">{`Since there's no ad network, there's no ad targeting. There's no Facebook bloat targeting you, and th    e site is `}<a parentName="li" {...{
          "href": "https://github.com/noahsark769/noahgilmore.com"
        }}>{`open source`}</a>{`. I use Google Analytics to track clicks on sponsored links so that I can make sure my analytics line up with those of the advertisers, but that's the extent of the data I'm tracking.`}</li>
      <li parentName="ol">{`If you use an ad blocker that supports blocking elements on the page by class name, you can opt out of ads entirely by blocking elements with the class name `}<inlineCode parentName="li">{`sponsorship-opt-out-point`}</inlineCode>{`. For Adblock Plus, for example, the rule looks like `}<inlineCode parentName="li">{`noahgilmore.com##.sponsorship-opt-out-point`}</inlineCode>{`. For sidebar content, you can also click the "hide" button to remove the section from the page.`}</li>
      <li parentName="ol">{`I'm interested in what you think of the ads and I plan to continue to be transparent about the process (in fact, this `}<a parentName="li" {...{
          "href": "https://twitter.com/i/web/status/1189661305780625408"
        }}>{`tweet`}</a>{` is what got the process started). Please feel free to `}<a parentName="li" {...{
          "href": "https://twitter.com/noahsark769"
        }}>{`reach out`}</a>{` anytime 👋`}</li>
    </ol>
    <p>{`I'm grateful to Instabug for being the first sponsor of this site. If your company would like to be displayed as a sponsor, feel free to `}<a parentName="p" {...{
        "href": "mailto:noah@noahgilmore.com"
      }}>{`reach out`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      